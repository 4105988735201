import { Auth } from 'aws-amplify'

class Address {
	constructor(request) {
		this.request = request
		this.endpoint = 'address'
	}
	
	async List(username) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		const body = {
			"username":username
		}
		return this.request.send(
			`${this.endpoint}/list/`,
			'POST',
			body,
			jwtToken
		)
	}
	
	/*gets an  address' details, should rename this for easier understanding*/
	async Get(username, addressID) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		const body = {
			"username":username,
			"uuid":addressID
		}
		return this.request.send(
			`${this.endpoint}/get/`,
			'POST',
			body,
			jwtToken
		)
	}
	
	async Add(username, components) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		const body = {
			"username":username,
			"components":components
		}
		return this.request.send(
			`${this.endpoint}/add/`,
			'POST',
			body,
			jwtToken
		)
	}
	
	async Update(username, AddressId, components) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		const body = {
			"username":username,
			"uuid":AddressId,
			"components":components
		}
		return this.request.send(
			`${this.endpoint}/update/`,
			'POST',
			body,
			jwtToken
		)
	}
	
	async Delete(username, addressID) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		const body = {
			"username":username,
			"uuid":addressID
		}
		return this.request.send(
			`${this.endpoint}/delete/`,
			'POST',
			body,
			jwtToken
		)
	}
	
	GetPickUpPoints() {
		/* api used before switched to static json list
		return this.request.send(
			`${this.endpoint}/pickuppoint/`,
			'GET',
		)
		*/
		return this.request.requestData(
			"https://cdn.ngnw.ph/lists/pickup/all.json"
		)
	}
	
	GetProvinceData() {
		return this.request.requestData(
			"https://geocode-ph.ngnw.ph/province.json"
		)
	}
	
	GetCityData(provinceCode) {
		return this.request.requestData(
			`https://geocode-ph.ngnw.ph/citylist/${provinceCode}.json`
		)
	}
	
	GetBarangayData(cityCode) {
		return this.request.requestData(
			`https://geocode-ph.ngnw.ph/barangaylist/${cityCode}.json`
		)
	}
}


export default Address