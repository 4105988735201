import wrapWithProvider from './wrap-with-provider'
import './src/style/global.css'
import './src/style/globalFont.css'
import './src/style/globalMargin.css'
import './src/style/globalColor.css'
import './src/style/globalDimension.css'
// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = wrapWithProvider

export const onRouteUpdate = ({ location, prevLocation }) => {
	const parameters = location.search.split("&")
	for(let i=0; i<parameters.length; i++) {
		if(parameters[i].split("=")[0]==="referral" || parameters[i].split("=")[0]==="?referral") {
			let referral = parameters[i].split("=")[1]
			referral = decodeURIComponent(referral)
			localStorage.setItem("referral", referral)
			break
			
		}
	}
}
/*
export const onRouteUpdate = ({ location, prevLocation }) => {
	
	//this section is the 1st version that adds referrer to location to every page 
	//to know if going outside or not of website, moved to version 2 the one with goBackNum 
	//because this can't skip pages, can delete this code with no problems 
	if(location && location.state) {
		location.state.referrer = prevLocation ? prevLocation.pathname : null
	}
	
	
	
	//version 3 with indexOf doesn't have skip pages functionality, should combine this with version 2(with window.goBackNum) if you want to skip certain pages
	//this doesn't properly handle when user uses browser forward button that isn't just 1 jump ahead(e.g. going back to your 1st history then going to your 10th history), would make window.locations have only 2 gatsby pages recorded and the in between history of those 2 would be forgotten
	//solution to the forward button problem:only do the location and key popping when adding an unrecorded page(meaning no key has been recorded), also might need to makes a list similar to window.locations to key so that we also record the location.key of the current page since we need to know if they just went back to the end of their history
	//also when combining this with goBackNum, need to think of a way on how to accurately know which goBackNum value is the applicable one since we can't just pop/decrement the last 1 like in version 2 since users can use the browser button to jump more than 2 pages in history
	//https://github.com/gatsbyjs/gatsby/issues/10410
	//window.customHistoryLength = window.history.length//if we need to skip certain pages, used to check if window.history.length is the same as this if not then just do a simple window.history.back(because only way for this to happen is if user is navigating to the same page(e.g. pagination)) if yes window.history.go(window.goBackNum), 
	if(prevLocation ) {
		//console.log(window.locations.indexOf(location.href) < 0, "WHY")
		window.locations = window.locations || [document.referrer] //moved this here rather than in the if statement because there's a chance user has prevLocation(already opened browser tab but no window.locations made yet), caution this might not be complete yet since other stuff like previousPath isn't initialized, also not sure if needed will need to test prevLocation on an already opened browser in demo.negosyonow.ph
		if(window.locations.indexOf(location.href) < 0) { //didn't go back
			//entirely new pathname/location that hasn't been recorded in window.locations
			window.locations.push(window.location.href)
			window.previousPath = window.locations[window.locations.length - 2]
			if(window.previousKey===undefined) {
				window.previousKey = [prevLocation.key]
			}else {
				window.previousKey.push(prevLocation.key)
			}
			
		}else {
			//console.log(window.previousKey.indexOf(location.key) >= 0, "fuck")
			if( window.previousKey.indexOf(location.key) >= 0 ) {
				
				//this page was accessed by history methods back/forward/go since the key has already been recorded
				//caution this doesn't take into account going back multiple steps and using the forward button to go back in 1 go(by holding it) since the locations and keys are deleted(aka pop) when user goes back rather than when user goes to a new page with an unregistered key
				while(window.previousKey[window.previousKey.length-1]!==location.key) {
					window.locations.pop()//since backbutton was triggered pop last 1 
					window.previousKey.pop()//for fixing the forward button issue(line 23 Sept, 19, 2020), need to move this(popping of locations and previousKey) to else below and if(window.location.indexOf..... above
				}
				//pop again when location.key=window.previousKey[maxLength]
				window.locations.pop()
				window.previousKey.pop()
				window.previousPath = window.locations[window.locations.length - 2]
			}else {
				//this url that has been recorded isn't part of the history yet or at least its location.key is not recorded)
				//console.log("CHECKING")
				window.locations.push(window.location.href)
				window.previousPath = window.locations[window.locations.length - 2]
				window.previousKey.push(prevLocation.key)

				

			}
		}
	}else {
		//initialize values, will need to add window.keys similar to window.locations since for the forward button issue 
		//we will need also the key of the page/location in the furthest in history to know correctly if the user just moved here using browser history 
		//that or just rename previousKey to window.keys and adjust the code above that pushes/adds on previousKey to do it for location.key and not prevLocation.key(will also need to adjust this to iniatialize it with [location.key])
		window.locations = [document.referrer]
		window.locations.push(window.location.href)
		window.previousPath = window.locations[window.locations.length - 2]
		window.previousKey = undefined
		//assumed newly entered from a link/direct url outside the website or at least it doesn't use <Link> or navigate
	}
	
	//version 2, can skip pages with the custom back button, abandoned since indexOf has less 
	//bugs(this only took into account users going back or going forward in history by 1 step) 
	//and we didn't need to skip pages anymore, also possibly missed other bugs(like not taking 
	//into account going forward/back in history by more than 1 step) when testing this, should only use part of this namely the window.goBackNum incrementing 
	if(prevLocation) {
		
		if(location.href!==window.locations[window.locations.length-2] && //used browser back button
			 location.href!==window.locations[window.locations.length-1+window.goBackNum[window.goBackNum.length-1]] //used custom back button
		) { //didn't go back
			window.goBackNum = window.goBackNum || [-1] //just in case it hasn't been initialize
			window.locations = window.locations || [document.referrer] //just in case it hasn't been initialize
			window.locations.push(window.location.href)
			
			if(window.previousKey===undefined) {
				window.previousKey = [prevLocation.key]
			}else {
				
				window.previousKey.push(prevLocation.key)
			}
			if(
				prevLocation.pathname==="/forgot-password/" || 
				prevLocation.pathname==="/sign-in/" ||
				prevLocation.pathname==="/reset-password/" ||
				prevLocation.pathname==="/changePassword/" ||
				prevLocation.pathname==="/app/verify-contact/" ||
				prevLocation.pathname==="/register/"
			){
				// prevLocation/prev page must be skipped so decrement the last goBackNum since we want to skip the previous page if we used custom back button
				window.goBackNum[window.goBackNum.length-1] = window.goBackNum[window.goBackNum.length-1]-1
			}else {
				//new page that's not the in the list yet, so push a new -1 to goBackNum
				window.goBackNum.push(-1)
			}
			
			window.previousPath = window.locations[window.locations.length - 1+window.goBackNum[window.goBackNum.length-1]]
		}else {
			
			//combine this part(checking window.previousKey) with the indexOf version(version 3 above) since this only takes into account going forward/back in history by 1 step compared to indexOf that can handle going more than 1 step back
			if( window.previousKey[window.previousKey.length+window.goBackNum[window.goBackNum.length-1]]===location.key) {
				//used custom back button
				for(let i=0; i<(-1*window.goBackNum[window.goBackNum.length-1]);i++) {
					//console.log("double shit")
					window.locations.pop()//since backbutton was triggered pop last how many times went back
					window.previousKey.pop()
				}
				window.goBackNum.pop()
				window.previousPath = window.locations[window.locations.length - 1+window.goBackNum[window.goBackNum.length-1]]
				//update goBackNum by checking what window.location
			}else if( window.previousKey[window.previousKey.length-1]===location.key) {
				//used browser back button
				
				window.goBackNum[window.goBackNum.length-1] = window.goBackNum[window.goBackNum.length-1]+1
				if(window.goBackNum[window.goBackNum.length-1]===0) {
					window.goBackNum.pop()
				}
				window.locations.pop()//since backbutton was triggered pop last 1
				window.previousKey.pop()
				window.previousPath = window.locations[window.locations.length - 1+window.goBackNum[window.goBackNum.length-1]]
			}else {
				//console.log("CHECKING")
				window.goBackNum = window.goBackNum || -1
				window.locations = window.locations || [document.referrer]
				window.locations.push(window.location.href)
				
				window.previousKey.push(prevLocation.key)
				if(
					prevLocation.pathname==="/forgot-password/" || 
					prevLocation.pathname==="/sign-in/" ||
					prevLocation.pathname==="/reset-password/" ||
					prevLocation.pathname==="/changePassword/" ||
					prevLocation.pathname==="/app/verify-contact/" ||
					prevLocation.pathname==="/register/"
				){
					// prevLocation/prev page must be skipped so decrement the last goBackNum since we want to skip the previous page if we used custom back button
					window.goBackNum[window.goBackNum.length-1] = window.goBackNum[window.goBackNum.length-1]-1
				}else {
					//new page that's not the in the list yet, so push a new -1 to goBackNum
					window.goBackNum.push(-1)
				}
				window.previousPath = window.locations[window.locations.length - 1+window.goBackNum[window.goBackNum.length-1]]

			}
		}
	}else {
		//initialize value
		window.locations = [document.referrer]
		window.locations.push(window.location.href)
		window.previousPath = window.locations[window.locations.length - 2]
		window.goBackNum = [0]//don't go back anymore
		//assumed newly entered from a link/direct url outside the website or at least it doesn't use <Link> or navigate
	}
	
	
}
*/