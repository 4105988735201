
class Category {
	constructor(request) {
		this.request = request
		this.endpoint = 'category'
	}
	
	Search(category, fail) {
		//fail was just for testing error handling
		/* api used before switching to static json list
		const body = {
			"categoryurl":category
		}
		
		return this.request.send(
			`product/search/`,
			'POST',
			body,
			undefined,
			fail
		)
		*/
		return this.request.requestData(`https://cdn.ngnw.ph/lists/category/category/${category}.json`)
	}
}


export default Category