
class Courier {
	constructor(request) {
		this.request = request
		this.endpoint = 'courier'
	}
	
	GetDeliveryFee(locationCode) {
		const body = {
			"locationcode":locationCode
		}
		return this.request.send(
			`${this.endpoint}/deliveryfee/location`,
			'POST',
			body,
		)
		
	}
}


export default Courier