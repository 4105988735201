
import { Auth } from 'aws-amplify'

class Product {
	constructor(request) {
		this.request = request
		this.endpoint = 'product'
	}
	
	/* Search Products via category
	 * maybe should rename this function to searchCategory since its not obvious this is for searching through a category
	 */
	Search(category, fail ) {
		//fail was just for testing error handling
		/* api used before switching to static json list
		const body = {
			"categoryurl":category
		}
		
		return this.request.send(
			`${this.endpoint}/search/`,
			'POST',
			body,
			undefined,
			fail
		)
		*/
		return this.request.requestData(`https://cdn.ngnw.ph/lists/product/category/${category}.json`)
	}
	
	SearchTop(category) {
		return this.request.requestData(`https://cdn.ngnw.ph/lists/product/category/top/${category}.json`)
	}
	
	SearchText(text) {
		const body = {
			"searchtext":text
		}
		return this.request.send(
			//`${this.endpoint}/search/`,
			`search/${this.endpoint}/`,
			'POST',
			body
		)
	}

	SearchOrganizationProduct(id) {
		/* api used before switching to static json list
		const body = {
			"organizationId":id,
		}
		return this.request.send(
			`${this.endpoint}/search/`,
			'POST',
			body
		)
		*/
		return this.request.requestData(`https://cdn.ngnw.ph/lists/product/organization/${id}.json`)
	}

	SearchBlogProduct(id) {
		/*
			No graphql API for the product in recipes/blog pages 
		*/
		return this.request.requestData(`https://cdn.ngnw.ph/lists/product/recipe/${id}.json`)
	}
	
	SearchMultiple(query) {
		const body = {
			query
		}
		return this.request.send(
			//`${this.endpoint}/search/`,
			`search/${this.endpoint}/`,
			'POST',
			body
		)
	}
	
	RequestProduct(info) {
		return this.request.send(
			`${this.endpoint}/request`,
			'POST',
			info,
			undefined,
			undefined, 
			{
				"configkey":"599abe4d9df0b4711d22cd9bc00d175a"
			}
		)
	}
	
	async GetProductSectionExclusive(url, username) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		const body = {
			username:username,
			url: url
		}
		return this.request.send(
			`${this.endpoint}/view`,
			'POST',
			body,
			jwtToken
		)
	}

	GetProductSection(url) {
		return this.request.requestData(
			`https://cdn.ngnw.ph/details/product/${url}.json`
		)
	}
}


export default Product