import React, {Component} from 'react'
import CartContext from './CartContext'

const Negosyo = require('../../../lib/negosyo')

class CartProvider extends Component {
	state = {
		cartId:null,
		cartCount:0,
		gettingCartId:true,
		timeOutRef:null,
	}

	updateCartCount = (cartCount, cartId) => {
		localStorage.setItem(
			'mdata', 
			JSON.stringify({cartId, cartCount})
		)
		this.setState({cartCount:cartCount, cartId:cartId})
	}
	
	initiateRetry = () => {
		return new Promise(resolve => {
			this.setState( prevState => ({
				gettingCartId:true
			}), ()=>{
				this.getCartId(5, 100)
				resolve(true)
			})
		})
	}
	
	resetProvider = () => {
		//goes back to square one(initial state of cartProvider), should be called only after a checkout
		//to reset the cartProvider to be sure there's no holdover data from the previous cartId
		this.setState({
			cartId:null,
			cartCount:0,
			gettingCartId:true,
		}, ()=> {
			localStorage.removeItem('mcart')
			localStorage.removeItem('mdata')
			this.getCartId(5, 100);
		}) //timeOutRef isn't reset just in case a timeOut is still there
	}
	
	getCartId = (maxRetry = 5, retryDelay=100, fail=false) => {
		const cartId = localStorage.getItem('mcart')
		const mdata = localStorage.getItem('mdata')
		if(!cartId) {
			if(maxRetry>0) {
				//the fail is for testing purposes
				Negosyo.requestCartId(fail)
				.then((response)=>{
					if(response.status==="OK") {
						const cartId = response.data
						localStorage.setItem(
							'mcart', 
							cartId
						)
						localStorage.setItem(
							'mdata', 
							JSON.stringify({cartId, cartCount: 0})
						)
						this.setState({
							cartId:cartId,
							gettingCartId:false,
							cartCount:0
						})
					}else {
						//not sure what would cause api to send an error but its here just in case
						const temp = setTimeout(()=>{
							this.getCartId(maxRetry-1, retryDelay*2, fail)
						}, retryDelay)
						this.setState({
							timeOutRef:temp
						})
					}
				}).catch((err)=>{
					const temp = setTimeout(()=>{
						this.getCartId(maxRetry-1, retryDelay*2, fail)
					}, retryDelay)
					this.setState({
						timeOutRef:temp
					})
				})
			}else {
				this.setState({
					gettingCartId:false
				})
				
			}
				
		}else if(!mdata) {
			if(maxRetry > 0 ) {
				Negosyo.getCartItems(cartId)
				.then(({data, meta}) => {
					let total = 0
					for(let i = 0; i<data.length; i++) {
						total += data[i].data.reduce((a, c) => a + c.quantity, 0)
					}
					this.updateCartCount(total, cartId)
					this.setState({
						gettingCartId:false
					})
				}).catch((err)=>{
					const temp = setTimeout(()=>{
						this.getCartId(maxRetry-1, retryDelay*2, fail)
					}, retryDelay)
					this.setState({
						timeOutRef:temp
					})
					
				})
			}else {
				this.setState({
					gettingCartId:false
				})
			}
				
		}else {
			const data = localStorage.getItem('mdata')
			const parsedData = JSON.parse(data)
			this.setState({
				cartId:cartId,
				cartCount:(parsedData.cartCount || 0),
				gettingCartId:false
			})
		}
	}
	
	
	componentDidMount() {
		this.getCartId(5, 100);
		
	}
	componentWillUnmount() {
		clearTimeout(this.state.timeOutRef);
	}
	
	render() {
		const {
			updateCartCount,
			initiateRetry,
			resetProvider
		} = this
		const {
			cartId,
			cartCount,
			gettingCartId,
		} = this.state
		const {
			children
		} = this.props
		return (
			<CartContext.Provider
				value={{
					cartId,
					cartCount,
					updateCartCount,
					gettingCartId,
					initiateRetry,
					resetProvider
				}}
			>
				{children}
			</CartContext.Provider>
		)
	}
}

export default CartProvider

