import { Auth } from 'aws-amplify'

class Contact {
	constructor(request) {
		this.request = request
		this.endpoint = 'contact'
	}
	
	/* should rename this to something like GetUserInfo or such
	 * gets the user logintype(password or otp), username status(Inactive, None, Active)
	 * also maybe it be better to move this a new file called user.js or rename this file to user.js
	 */
	Get(phone_number) {
		const body = {
			"contact":phone_number
		}
		return this.request.send(
			`${this.endpoint}/get`,
			'POST',
			body,
		)
	}
	
	async ListContact (username) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		const body = {
			username:username
		}
		return this.request.send(
			`${this.endpoint}/list`,
			'POST',
			body,
			jwtToken
		)
	}

	async RequestValidation(contactinfo, username) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})

		const body = {
			"contact":contactinfo,
			"username":username
		}
		return this.request.send(
			`${this.endpoint}/validatecontactrequest`,
			'POST',
			body,
			jwtToken
		)
	}

	async FinalizeValidation(validationcode, contactinfo, username) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})

		const body = {
			"contact":contactinfo,
			"username":username,
			"verification": validationcode
		}
		return this.request.send(
			`${this.endpoint}/validatecontact`,
			'POST',
			body,
			jwtToken
		)
	}

	/* should rename this to RegisterUser since its not obvious based on the file name and 
	 * function name what is it registering for
	 * also maybe it be better to move this a new file called user.js or rename this file to user.js
	 */
	Register(phone_number, loginType, name) {
		const body = {
			contact:phone_number,
			logintype:loginType,
			profile:{
				name:name
			}
		}
		return this.request.send(
			`${this.endpoint}/register`,
			'POST',
			body,
		)
	}
	
	ResendCode(phone_number, username) {
		const body = {
			"contact":phone_number,
			"username":username
		}
		return this.request.send(
			`${this.endpoint}/resendcode`,
			'POST',
			body,
		)
	}
	
	/*like the ResendCode except it doesn't actually send an otp but responds with the otp 
	 *for secret sign in with otp (like for checkout) and register(september-8-2020)
	 */
	GetOTPCode(phone_number, username) {
		const body = {
			"contact":phone_number,
			"username":username
		}
		return this.request.send(
			`${this.endpoint}/getotp`,
			'POST',
			body,
		)
	}
	
	async ChangePassword(username, newPassword) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		const body = {
			"username":username,
			"password":newPassword
		}
		return this.request.send(
			`${this.endpoint}/changepassword`,
			'POST',
			body,
			jwtToken
		)
	}
}


export default Contact