import {
	buildCartItemData,
} from './helpers'

import { Auth } from 'aws-amplify'


class Cart  {
	constructor(request, id) {
		this.request = request
		this.cartId = id
		this.endpoint = 'cart'
	}
	
	RequestCartId(fail) {
		return this.request.send(
			`${this.endpoint}/create`,
			'GET',
			undefined,
			undefined,
			fail
		)
	}
	
	ConfirmOrder(contact, orderCode, otp) {
		const body = {
			contact,
			orderCode,
			otp
		}
		return this.request.send(
			`${this.endpoint}/confirm`,
			'POST',
			body
		)
	}
	
	Items() {
		return this.request.send(
			`${this.endpoint}/${this.cartId}/items`,
			'GET'
		)
	}

	async UserItems(username) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		const body = {
			username:username,
		}
		return this.request.send(
			`${this.endpoint}/${this.cartId}/useritems`,
			'POST',
			body,
			jwtToken
		)
	}
	
	async UserEditQuantity(username, cartItemId, quantity) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})

		const body = {
			quantity:quantity,
			username: username
		}
		return this.request.send(
			`${this.endpoint}/${this.cartId}/items/${cartItemId}`,
			'POST',
			body,
			jwtToken
		)
	}
	
	EditQuantity(cartItemId, quantity) {
		const body = {
			quantity:quantity
		}
		return this.request.send(
			`${this.endpoint}/${this.cartId}/items/${cartItemId}`,
			'POST',
			body
		)
	}
	
	async GetCartHistory(username, statusFilter="Ordered") {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		const body = {
			username:username,
			
		}
		/*
		const body = {
			username:username,
			status:statusFilter
		}
		*/
		return this.request.send(
			`${this.endpoint}/list`,
			'POST',
			body,
			jwtToken
		)
	}
	
	AddProduct(productId, quantity = 1, notes = "") {
		const body = buildCartItemData(productId, quantity, notes)
		return this.request.send(
			`${this.endpoint}/${this.cartId}/items`, 
			'POST', 
			{
				...body,
			}
		)
	}


  
	async UserRemoveItem(username, itemId) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		const body = {
			username:username,
		}

		return this.request.send(
			`${this.endpoint}/${this.cartId}/items/${itemId}`,
			'DELETE',
			body,
			jwtToken
		)
	} 
	

  
	RemoveItem(itemId) {
		return this.request.send(
			`${this.endpoint}/${this.cartId}/items/${itemId}`,
			'DELETE'
		)
	} 

	async Checkout(data) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		return this.request.send(
			`${this.endpoint}/${this.cartId}/checkout`,
			'POST',
			data,
			jwtToken,
		)
	}
}

export default Cart
