import React from 'react'

const CartContext = React.createContext({
	cartId: null,
	cartCount: 0,
	gettingCartId: true,
	initiateRetry: () => {},
	updateCartCount: () => {},
	resetProvider: () => {}
})

export default CartContext
