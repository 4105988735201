
import { Auth } from 'aws-amplify'

class Customer {
	
	Register(username, password ) {
		const promise = new Promise((resolve, reject) => {
			Auth.signUp({ username, password })
			.then((data)=>{
				//needs the resolve so that it goes back to the one that called this properly
				resolve("success")
			})
			.catch( (err)=>{
				reject(err)
			})
		})
		return promise
	}

	Login(username, password) {
		const promise = new Promise((resolve, reject) => {
			Auth.signIn(username, password)
			.then((user)=>{
				//needs the resolve so that it goes back to the one that called this properly
				resolve("success")
			})
			.catch ( (err)=> {
				reject(err)
			})
		})
		return promise 
	}
	
	LoginOTP(username, otp) {
		const promise = new Promise((resolve, reject) => {
			Auth.signIn(username)
			.then((user)=>{
				if (user.challengeName === 'CUSTOM_CHALLENGE') {
					Auth.sendCustomChallengeAnswer(user, otp)
					.then((user)=>{
						// Successful login
						//needs the resolve so that it goes back to the one that called this properly
						resolve("success")
					})
					.catch((err)=> {
						if(err.message.toLowerCase()==="incorrect username or password.") {
							reject("Incorrect OTP")
						}else {
							reject(err.message)
						}
					})
				} else {
					// other situations, error?
					//return Auth.currentAuthenticatedUser()
				}
			})
			.catch ( (err)=> {
				if(err.message.toLowerCase()==="incorrect username or password.") {
					//console.log(err)
					reject("Incorrect OTP")
				}else {
					reject(err.message)
				}
			})
		})
		return promise 
	}
	
	//get current user details 
	GetCurrentUserDetails() {
		const promise = new Promise((resolve, reject) => {
			if(localStorage.getItem("customer")) {
				resolve(JSON.parse(localStorage.getItem("customer")))
			} else {
				Auth.currentAuthenticatedUser()
				.then((user)=>{
					const userInfo = {
						...user.attributes,
						username: user.username
					}
					localStorage.setItem('customer', JSON.stringify(userInfo))
					resolve(userInfo)
				})
				.catch ( (err)=> {
					reject(err)
				})
			}
		})
		return promise
	}

	//returns jwt id token if logged in, if not returns identity id
	/*not really that much used(currently only used in checkout but even then its result isn't being used 
	 *so if you take out this from checkout this should be fine to delete)
	 */
	GetRelevantToken() {
		const promise = new Promise( (resolve, reject) => {
			Auth.currentSession()
			.then((data)=>{
				resolve(data.idToken.jwtToken)
			})
			.catch ( (err)=> {
				if(err==="No current user") {
					Auth.currentCredentials()
					.then((data)=>{
						resolve(data.identityId)
					})
					.catch ( (err)=> {
						//console.log("error in creds " + err)
						reject(err)
					})
				}else {
					reject(err)
				}
				//console.log("error in GetRelevantToken " + err)
			})
			
			
			
		})
		return promise
	}

	//Get Identity token/identity id
	GetIdentityCredentials() {
		const promise = new Promise((resolve, reject) => {
			Auth.currentCredentials()
			.then((data)=>{
				resolve(data.identityId)
			})
			.catch ( (err)=> {
				reject(err)
			})
		})
		return promise
	}

	//get jwtToken for api calls
	GetCurrentUser() {
		const promise = new Promise((resolve, reject) => {
			Auth.currentSession()
			.then((data)=>{
				resolve(data.idToken.jwtToken)
			})
			.catch ( (err)=> {
				reject(err)
			})
		})
		return promise
	}
	
	
	
	ChangePassword(oldPass, newPass) {
		const promise = new Promise((resolve, reject) => {
			Auth.currentAuthenticatedUser()
			.then((user)=>{
				Auth.changePassword(user, oldPass, newPass)
				.then(()=>{
					resolve("success")
				}).catch((err)=>{
					reject(err.message)
					
				})
			})
			.catch ( (err)=> {
				reject(err)
			})
		})
		return promise
	}
	
	LogOut() {
		const promise = new Promise((resolve, reject) => {
			Auth.signOut()
			.then((data)=>{
				localStorage.removeItem('customer')
				resolve(data)
			})
			.catch ( (err)=> {
				reject(err)
			})
		})
		return promise
	}
}


export default Customer



