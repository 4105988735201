import fileDownload from 'js-file-download'
import { buildRequestBody, parseJSON,  } from './helpers'
//const fetch = require(`node-fetch`)


class Credentials {
	constructor(client_id, access_token, expires) {
		this.client_id = client_id
		this.access_token = access_token
		this.expires = expires

	}
}

class Request  {
	constructor(config) {
		this.config = config

	}

	/*moltin authenticate, will leave this here as a future reference if we want authentication similar to this*/
	authenticate() {
		const { config } = this

		if (!config.client_id) {
			throw new Error('You must have a client_id set')
		}

		if (!config.host) {
			throw new Error('You have not specified an API host')
		}

		const body = {
			grant_type: 'implicit',
			client_id: config.client_id
		}


		const promise = new Promise((resolve, reject) => {
			fetch(`${config.protocol}://${config.host}/${config.auth.uri}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'X-MOLTIN-SDK-LANGUAGE': config.sdk.language,
					'X-MOLTIN-SDK-VERSION': config.sdk.version
				},
				body: Object.keys(body)
					.map(k => `${encodeURIComponent(k)}=${encodeURIComponent(body[k])}`)
					.join('&')
			})
			.then(parseJSON)
			.then(response => {
				if (response.ok) {
					resolve(response.json)
				}

				reject(response.json)
			})
			.catch(error => reject(error))
		})

		promise
		.then(response => {
			const credentials = new Credentials(
				config.client_id,
				response.access_token,
				response.expires
			)
			if(typeof window !=='undefined')
			{
				localStorage.setItem('moltinCredentials', JSON.stringify(credentials))
			}
		})
		.catch(() => {})

		return promise
	}

	send(uri, method, body = undefined, jwtToken = undefined, fail=false, extraInfo)  {

		const { config } = this;
		const promise = new Promise((resolve, reject) => {
			const req = () => {
				//fail is for testing purposes for failure of api/testing catch of functions that gets these promises
				/*
				let headers = {
					"Content-Type":'application/json'
				}
				if(fail===true) {
					headers["Content-Type"] = 'applicat'
				}
				*/
				const headers = {
					'Content-Type': 'application/json'
				}

				if(jwtToken!==undefined) {
					headers.Authorization = jwtToken
				}
				fetch(`${config.protocol}://${config.host}/${uri}`, {
					method: method.toUpperCase(),
					headers,
					body: buildRequestBody(body, extraInfo),
				})
				.then(parseJSON)
				.then(response => {
					if (response.ok) {
						resolve(response.json)
					}
					reject(response.json)
				})
				.catch( (error) => {
					reject( error )
				})
			}
			return req();
		})

		return promise
	}

	//for getting the address data for province/city/barangay
	requestData(url) {
		const promise = new Promise((resolve, reject) => {
			const req = () => {

				fetch(url)
				.then(parseJSON)
				.then(response => {
					if (response.ok) {
						resolve(response.json)
					}
					reject(response.json)
				})
				.catch(error => reject(error))
			}
			return req();
		})

		return promise
	}

	downloadSecureData(suburl, jwtToken) {
		const promise = new Promise((resolve, reject) => {
			const req = () => {
				const headers = {
					'Content-Type': 'application/json'
				}

				if(jwtToken!==undefined) {
					headers.Authorization = jwtToken
				}
				fetch("https://download.negosyonow.com/"+suburl, {
					method: "GET",
					headers
				})
				.then((response) => response.blob())
				.then((blob) => {
					var filename = suburl.substring(suburl.lastIndexOf('/')+1);
					fileDownload(blob, filename);
				})
				.catch( (error) => {
					reject( error )
				})
			}
			return req();
		})

		return promise
	}


}

export default Request



/*
	// 2020 2 7 - Moltin version
	send(uri, method, body = undefined)  {

		const { config } = this;
		const promise = new Promise((resolve, reject) => {
			var credentials
			if( typeof window !== 'undefined') {
				credentials = JSON.parse(localStorage.getItem('moltinCredentials'))
			}
			const req = ({ access_token }) => {

				const headers = {
					Authorization: `Bearer: ${access_token}`,
					'Content-Type': 'application/json',
					'X-MOLTIN-SDK-LANGUAGE': config.sdk.language,
					'X-MOLTIN-SDK-VERSION': config.sdk.version
				}

				fetch(`${config.protocol}://${config.host}/${config.version}/${uri}`, {
					method: method.toUpperCase(),
					headers,
					body: buildRequestBody(body)
				})
				.then(parseJSON)
				.then(response => {
					if (response.ok) {

						resolve(response.json)
					}

					reject(response.json)
				})
				.catch(error => reject( "testing errors: " + error))
			}
			if (
				!credentials ||
				!credentials.access_token ||
				credentials.client_id !== config.client_id ||
				Math.floor(Date.now() / 1000) >= credentials.expires
			) {
				return this.authenticate()
				.then(() => {
					if(typeof window !== 'undefined') {
						req(JSON.parse(localStorage.getItem('moltinCredentials')))
					}

				})
				.catch(error => reject(error))
			}
			return req(credentials)
			//return req();
		})

		return promise
	}
*/
