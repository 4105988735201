
import { Auth } from 'aws-amplify'
class Profile {
	constructor(request) {
		this.request = request
		this.endpoint = 'profile'
	}
	
	async GetProfile(username) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		
		const body = {
			username:username
		}
		return this.request.send(
			`${this.endpoint}/get`,
			'POST',
			body,
			jwtToken
		)
	}
	
	async EditProfile(username, attributes) {
		
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {
			
		})
		
		const body = {
			username:username,
			profile: {
				...attributes
			}
		}
		
		return this.request.send(
			`${this.endpoint}/update`,
			'POST',
			body,
			jwtToken
		)
	}
}


export default Profile