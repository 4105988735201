const NegosyoNowGateway = require('./negosyo/index').gateway;

/*
 *functions with return "" means that its a function from the ecommerce
 *starter reference that I either haven't implemented yet or just there
 */

const Negosyo = NegosyoNowGateway() ;

const addToCart = (cartId, productId, quantity, notes) => {
	return Negosyo.Cart(cartId).AddProduct(productId, quantity, notes);
}

const requestCartId = (fail) => {
	//null cartId since its not needed for the api and its the api that's gonna ask for it
	return Negosyo.Cart(null).RequestCartId(fail);
}

const confirmOrder = (contact, orderCode, otp) => {
	return Negosyo.Cart().ConfirmOrder(contact, orderCode, otp);
}

const getCartItems = (id) => {
	return Negosyo.Cart(id).Items();
}

const getCartUserItems = (id, username) => {
	return Negosyo.OrderHistory.UserItems(id, username);
}

const editUserCartItemQuantity = (username, cartItemId, quantity, cartId) => {
	return Negosyo.Cart(cartId).UserEditQuantity(username, cartItemId, quantity)
}


const removeFromUserCart = (username, productId, cartId) => {
	return Negosyo.Cart(cartId).UserRemoveItem(username, productId);
}

const editCartItemQuantity = (cartItemId, quantity, cartId) => {
	return Negosyo.Cart(cartId).EditQuantity(cartItemId, quantity)
}

const removeFromCart = (productId, cartId) => {
	return Negosyo.Cart(cartId).RemoveItem(productId);
}

const getOrderHistory = (username, statusFilter) => {
	return Negosyo.OrderHistory.GetCartHistory(username, statusFilter);
}

//gets products with the category
const getProductSearch = (category, fail) => {
	return Negosyo.Product.Search(category, fail);
}

const getProductSearchText = (text) => {
	return Negosyo.Product.SearchText(text);
}

const getProductSearchOrganization = (id) => {
	return Negosyo.Product.SearchOrganizationProduct(id);
}

const getProductSearchBlog = (id) => {
	return Negosyo.Product.SearchBlogProduct(id);
}

const getProductSearchMultiple = (parameters) => {
	return Negosyo.Product.SearchMultiple(parameters);
}

//gets top 5 products of category url given
const getProductSearchTop = (category) =>{
	return Negosyo.Product.SearchTop(category);
}

const requestProduct = (body) => {
	return Negosyo.Product.RequestProduct(body);
}

const getProductSection = (url) => {
	return Negosyo.Product.GetProductSection(url);
}

const getProductSectionExclusive = (url, username) => {
	return Negosyo.Product.GetProductSectionExclusive(url, username);
}

// Franchise
const getFranchiseDetails = (url) => {
	return Negosyo.Franchise.GetDetails(url);
}
const getFranchiseQuestions = (url) => {
	return Negosyo.Franchise.GetDetailsQuestions(url);
}
const applyFranchise = (info) => {
	return Negosyo.Franchise.Apply(info);
}

const checkFranchiseRegistered = (username, url) => {
	return Negosyo.Franchise.IsRegistered(username, url);
}
const getFranchiseResources = (username, url) => {
	return Negosyo.Franchise.GetResources(username, url);
}
const getFranchiseFile = (username, url, fname) => {
	return Negosyo.Franchise.DownloadFile(username, url, fname);
}

const getFranchiseAll = (fail) => {
	return Negosyo.Franchise.Search("all", fail);
}

// Seminar
const getSeminarDetails = (url) => {
	return Negosyo.Seminar.GetDetails(url);
}
const getSeminarQuestions = (url) => {
	return Negosyo.Seminar.GetDetailsQuestions(url);
}
const registerSeminar = (info) => {
	return Negosyo.Seminar.Register(info);
}
const paySeminar = (info) => {
	return Negosyo.Seminar.Payment(info);
}
const checkSeminarRegistered = (username, url) => {
	return Negosyo.Seminar.IsRegistered(username, url);
}
const getSeminarResources = (username, url) => {
	return Negosyo.Seminar.GetResources(username, url);
}
const getSeminarFile = (username, url, fname) => {
	return Negosyo.Seminar.DownloadFile(username, url, fname);
}

const getSeminarAll = (fail) => {
	return Negosyo.Seminar.Search("all", fail);
}

/*gets categories with the category
 *also used to get menu, banners, carousel category, etc
 */
const getCategorySearch = (category, fail) => {
	return Negosyo.Category.Search(category, fail);
}

const checkoutCart = (cartId,data) => {
	return Negosyo.Cart(cartId).Checkout(data);
}

const payForOrder = (orderId, token, email) => {
	return ""; //from the moltin reference, unused so can delete this
}

//register new user to aws, should rename this to be more clear that its the aws register
const register = (username, password, phone_number) => {
	return Negosyo.Customer.Register(username, password, phone_number );
}

const login = (username, password ) => {
	return Negosyo.Customer.Login(username, password);
}

const loginOTP = (username, otp ) => {
	return Negosyo.Customer.LoginOTP(username, otp);
}

//may not be relevant anymore since get relevant token should return the same if not logged in
const getIdentityCredentials = () => {
	return Negosyo.Customer.GetIdentityCredentials();
}

//may not be relevant anymore since get relevant token should return the same if logged in
const getCurrentUser = () => {
	return Negosyo.Customer.GetCurrentUser();
}

const getCurrentUserDetails = () => {
	return Negosyo.Customer.GetCurrentUserDetails();
}

/*need to find a way to delete from localStorage after some time so that
 *you're sure the current user details from the current user isn't shown even though the
 *user is not treated as logged in anymore by amazon
 */
const isLoggedIn = () => {
	const promise = new Promise(async (resolve, reject) => {
		/*
		if(localStorage.getItem("customer")){
			resolve(true)
		}else {

		}
		*/
		await Negosyo.Customer.GetCurrentUser()
		.then( (data) => {
			resolve(true)
		}).catch( (err) => {
			if(err==="No current user") {
				resolve(false)
			}else {
				//some other reason why error happened other than no current user
				reject(err)
			}

		})
	});
	return promise;
}

// should return identity id if not logged in and jwt id token if logged in
const getRelevantToken = () => {
	return Negosyo.Customer.GetRelevantToken();
}

/*aws api to change user's password while knowing the old password*/
const changePassword = (oldPass, newPass) =>{
	return Negosyo.Customer.ChangePassword(oldPass, newPass);
}

const logOut = () => {
	return Negosyo.Customer.LogOut();
}

//get user status and username if it exists
const getUser = (phone_number) => {
	return Negosyo.Contact.Get(phone_number);
}

/*register in negosyonow, should rename this to be more clear its the register for negosyonow*/
const registerUser = (phone_number, loginType, name) => {
	return Negosyo.Contact.Register(phone_number, loginType, name);
}

/*sends an actual otp to user mobile*/
const resendNegosyoCode = ( phone_number, username ) => {
	return Negosyo.Contact.ResendCode(phone_number, username);
}

/*
	doesn't send otp to user mobile but rather responds with the otp to do secret log ins
	temporarily needed since we don't require users to input otp for sign up and for guest checkout
*/
const getOTPCode = ( phone_number, username ) => {
	return Negosyo.Contact.GetOTPCode(phone_number, username);
}

/*negosyonow api to change user's password without knowing the old password*/
const negosyoChangePassword = (username, password) => {
	return Negosyo.Contact.ChangePassword(username, password)
}

const requestContactValidation = (contactinfo, username) => {
	return Negosyo.Contact.RequestValidation(contactinfo, username)
}

const finalizeContactValidation = (validationcode, contactinfo, username) => {
	return Negosyo.Contact.FinalizeValidation(validationcode, contactinfo, username)
}

const listAddress = (username) => {
	return Negosyo.Address.List(username);
}

const getAddress = (username, addressId) => {
	return Negosyo.Address.Get(username, addressId);
}

const addAddress = (username, components) => {
	return Negosyo.Address.Add(username, components);
}

const updateAddress = (username, addressId, components) => {
	return Negosyo.Address.Update(username, addressId, components);
}

const deleteAddress = (username, addressId) => {
	return Negosyo.Address.Delete(username, addressId);
}

const getDeliveryFee = (locationCode) => {
	return Negosyo.Courier.GetDeliveryFee(locationCode);
}

const getPickUpPoints = () => {
	return Negosyo.Address.GetPickUpPoints();
}

const getProvinceData = () => {
	return Negosyo.Address.GetProvinceData();
}

const getCityData = (provinceCode) => {
	return Negosyo.Address.GetCityData(provinceCode);
}

const getBarangayData = (cityCode) => {
	return Negosyo.Address.GetBarangayData(cityCode);

}

const getUserProfile = (username) => {
	return Negosyo.Profile.GetProfile(username);
}

const editUserProfile = (username, attributes) => {
	return Negosyo.Profile.EditProfile(username, attributes);
}

const getContactList = (username) => {
	return Negosyo.Contact.ListContact(username)
}

module.exports = {
	addToCart,
	requestCartId,
	confirmOrder,
	getCartItems,
	getCartUserItems,
	editUserCartItemQuantity,
	removeFromUserCart,
	editCartItemQuantity,
	removeFromCart,
	checkoutCart,
	payForOrder,
	register,
	login,
	loginOTP,
	requestContactValidation,
	finalizeContactValidation,
	getProductSearch,
	getProductSearchOrganization,
	getProductSearchBlog,
	getProductSearchText,
	getProductSearchTop,
	requestProduct,
	getProductSection,
	getProductSectionExclusive,
	getCategorySearch,
	getIdentityCredentials,
	getCurrentUser,
	getCurrentUserDetails,
	isLoggedIn,
	logOut,
	getRelevantToken,
	changePassword,
	getUser,
	registerUser,
	resendNegosyoCode,
	getOTPCode,
	negosyoChangePassword,
	listAddress,
	getAddress,
	addAddress,
	updateAddress,
	deleteAddress,
	getPickUpPoints,
	getProvinceData,
	getCityData,
	getBarangayData,
	getUserProfile,
	editUserProfile,
	getOrderHistory,
	getContactList,
	getProductSearchMultiple,
	getDeliveryFee,
	getFranchiseDetails,
	getFranchiseQuestions,
	getFranchiseAll,
	checkFranchiseRegistered,
	getFranchiseResources,
	getFranchiseFile,
	applyFranchise,
	getSeminarAll,
	getSeminarDetails,
	getSeminarQuestions,
	registerSeminar,
	paySeminar,
	checkSeminarRegistered,
	getSeminarResources,
	getSeminarFile
}
