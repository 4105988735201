

export function parseJSON(response) {
	return new Promise( (resolve, reject) => {
		let responseBody;
		response.text().then(body => {
			responseBody = body;
			resolve({
				status: response.status,
				ok: response.ok,
				json: body !== '' ? JSON.parse(body) : '{}'
			})
		}).catch( (err) => {
			//I'm assuming this will only be triggered if JSON.parse(body) fails
			reject({
				status:response.status,
				ok:response.ok,
				message:responseBody || err
			});
		})
	})
}


export function buildRequestBody(body, extraInfo) {
	let parsedBody
	
	if (body) {
		if(extraInfo) {
			let temp = {
				data:body
			}
			temp = Object.assign(temp, extraInfo)
			parsedBody = JSON.stringify(temp)
		}else {
			parsedBody = `{
				"data": ${JSON.stringify(body)}
			}`
		}
		
	}
	return parsedBody
}


export function buildCartItemData(id, quantity = null, notes = "", type = 'cart_item') {
	const payload = {
		type
	}

	if (type === 'cart_item') {
		Object.assign(payload, {
			id,
			quantity: parseInt(quantity, 10),
			note: notes
		})
	}
	return payload
}


