exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/changePassword.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-confirm-js": () => import("./../../../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-franchise-js": () => import("./../../../src/pages/franchise.js" /* webpackChunkName: "component---src-pages-franchise-js" */),
  "component---src-pages-howto-js": () => import("./../../../src/pages/howto.js" /* webpackChunkName: "component---src-pages-howto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-account-address-js": () => import("./../../../src/pages/my-account/address.js" /* webpackChunkName: "component---src-pages-my-account-address-js" */),
  "component---src-pages-my-account-contact-js": () => import("./../../../src/pages/my-account/contact.js" /* webpackChunkName: "component---src-pages-my-account-contact-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-my-account-orders-cancel-js": () => import("./../../../src/pages/my-account/orders/cancel.js" /* webpackChunkName: "component---src-pages-my-account-orders-cancel-js" */),
  "component---src-pages-my-account-orders-completed-js": () => import("./../../../src/pages/my-account/orders/completed.js" /* webpackChunkName: "component---src-pages-my-account-orders-completed-js" */),
  "component---src-pages-my-account-orders-js": () => import("./../../../src/pages/my-account/orders.js" /* webpackChunkName: "component---src-pages-my-account-orders-js" */),
  "component---src-pages-my-account-orders-ongoing-js": () => import("./../../../src/pages/my-account/orders/ongoing.js" /* webpackChunkName: "component---src-pages-my-account-orders-ongoing-js" */),
  "component---src-pages-my-account-password-js": () => import("./../../../src/pages/my-account/password.js" /* webpackChunkName: "component---src-pages-my-account-password-js" */),
  "component---src-pages-pick-up-transactions-js": () => import("./../../../src/pages/pick-up-transactions.js" /* webpackChunkName: "component---src-pages-pick-up-transactions-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-list-js": () => import("./../../../src/pages/product-list.js" /* webpackChunkName: "component---src-pages-product-list-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-request-product-js": () => import("./../../../src/pages/request-product.js" /* webpackChunkName: "component---src-pages-request-product-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-seminar-js": () => import("./../../../src/pages/seminar.js" /* webpackChunkName: "component---src-pages-seminar-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/BlogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-tag-page-js": () => import("./../../../src/templates/BlogTagPage.js" /* webpackChunkName: "component---src-templates-blog-tag-page-js" */),
  "component---src-templates-franchise-apply-page-js": () => import("./../../../src/templates/FranchiseApplyPage.js" /* webpackChunkName: "component---src-templates-franchise-apply-page-js" */),
  "component---src-templates-franchise-content-page-js": () => import("./../../../src/templates/FranchiseContentPage.js" /* webpackChunkName: "component---src-templates-franchise-content-page-js" */),
  "component---src-templates-franchise-page-js": () => import("./../../../src/templates/FranchisePage.js" /* webpackChunkName: "component---src-templates-franchise-page-js" */),
  "component---src-templates-list-category-product-js": () => import("./../../../src/templates/ListCategoryProduct.js" /* webpackChunkName: "component---src-templates-list-category-product-js" */),
  "component---src-templates-organization-page-js": () => import("./../../../src/templates/OrganizationPage.js" /* webpackChunkName: "component---src-templates-organization-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-seminar-content-page-js": () => import("./../../../src/templates/SeminarContentPage.js" /* webpackChunkName: "component---src-templates-seminar-content-page-js" */),
  "component---src-templates-seminar-page-js": () => import("./../../../src/templates/SeminarPage.js" /* webpackChunkName: "component---src-templates-seminar-page-js" */),
  "component---src-templates-seminar-register-page-js": () => import("./../../../src/templates/SeminarRegisterPage.js" /* webpackChunkName: "component---src-templates-seminar-register-page-js" */)
}

