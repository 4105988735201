import { Auth } from 'aws-amplify'


class OrderHistory  {
	constructor(request) {
		this.request = request
		this.endpoint = 'orderhistory'
	}

	async UserItems(cartid, username) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {

		})
		const body = {
			username:username,
		}
		return this.request.send(
			`${this.endpoint}/${cartid}/useritems`,
			'POST',
			body,
			jwtToken
		)
	}

	async GetCartHistory(username, statusFilter="", pageOffset=0) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {

		})
		const body = {
			username:username,
			statusfilter:statusFilter,
			pageoffset:pageOffset

		};
		return this.request.send(
			`${this.endpoint}/list`,
			'POST',
			body,
			jwtToken
		)
	}

}

export default OrderHistory
